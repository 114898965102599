import './ChildComponentUI.scss';
import React, { Fragment, memo, useEffect, useMemo, useState } from 'react';
import Chat from '../../../components/UI/Chat/Chat';
import { SVG, classSelector, selectIcon, textColorSelector } from '../../../components/UI/selector';
import { CheckAccess, checkCount, CheckValue, getAvailableUser, getOndemandAvailableUser, getTime } from '../../../services/CommonFunction';
import { imageError } from '../../../services/subscriberService';
import { changeVolume, getAudioVolume, getDefaultValue, InputRange } from '../../../components/UI/InputRange/InputRange';
import Pagination from '../Pagination/Pagination';
import ToolTips from '../../../components/UI/ToolTips/ToolTips';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { setStartStreamForRecording } from '../SocketFun';
import { themeColor } from '../../../services/Constants';
import HideUnhideDiv from '../HideUnhideDiv/HideUnhideDiv';
import OutsideAlerter from '../../../components/UI/OutsideAlerter/OutsideAlerter';

export const Logo = ({ logo_thumb }) => {
    const logoUI = useMemo(() => <LogoUI logo_thumb={logo_thumb} />,[logo_thumb])
    return (
        <Fragment>
            {logoUI}
        </Fragment>
    )
}
const LogoUI = ({ logo_thumb }) => (
    <div className="logoBox">
        <img className="logoImage" alt="placeholder" src={logo_thumb} onError={imageError} />
    </div>
)

export const CenterCoachProfile = ({ profile_pic_thumb }) => {
    const coachProfilePic = useMemo(() => <CoachProfilePic src={profile_pic_thumb} imageClas={''} parentClas={'coach_div'} />,[profile_pic_thumb]);
    return (
        <Fragment>
            {coachProfilePic}
        </Fragment>
    )
}
const CoachProfilePic = ({ src, imageClas, parentClas }) => (
    <div className={parentClas}>
        <img src={src} className={imageClas} alt='profile_pic' />
    </div>
)

export const StopWatch = ({ duration, ...props }) => {
    const { minutes, second } = getTime(duration);
    const timeUIMinute = useMemo(() => <TimeUI key={'minutes'} text="Min" time={minutes} />,[minutes]);
    const timeUISecond = useMemo(() => <TimeUI key={'second'} text="Min" time={second} />,[second]);

    const stopWatchUI = useMemo(() => (
        <div className="stopWatch">
            <span className="title">Starts in :</span>
            <div className="time">
                {timeUIMinute}
                {timeUISecond}
            </div>
        </div>
    ),[minutes,second]);
    return (
        <Fragment>
            {stopWatchUI}
        </Fragment>
    )
}

const TimeUI = ({ text, time, ...props }) => {
    const themeColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');
    return (
        <div className="timeUI">
            <span className={`number ${themeColor === 'light' ? 'light' : 'dark'}`}>{time}</span>
        </div>
    )
}

export const PinUser = ({ allUsers, userPin, tagUsers }) => {
    const user = tagUsers.filter(el => el.showStream).map((data, index) => {
        const el = { ...allUsers[data.user_id] };
        return <PinUserUI key={el.user_id} {...el} userPin={userPin} />
    })
    const noState = <Nostate key={'noState'} />
    return (
        <HideUnhideDiv clasType={`left`} clas={`pinUser${user.length ? '' : ' noState'}`}>
            {user.length ? user : noState}
        </HideUnhideDiv>
    )
}

const PinUserUI = ({ first_name, user_id, userPin, profile_pic_thumb, me }) => {
    const pinIcon = useMemo(() => <PinIcon userPin={userPin} user_id={user_id} parentClas='userPin active' />,[user_id]);
    const nameUI = useMemo(() => <Name me={me} name={first_name} showPin={true} clasName={'userName'} />,[first_name]);
    const profilePic = useMemo(() => <ProfilePic imageClas='' src={profile_pic_thumb} />,[profile_pic_thumb]);
    return (
        <div className={`userUi${me ? " me" : ''}`}>
            <div className="userDetail">
                {profilePic}
                {nameUI}
            </div>
            {pinIcon}
        </div>
    )
}

const ProfilePic = ({ src, imageClas }) => (
    <div className="userPicture">
        <img className={imageClas} src={(CheckValue(src)) ? src : selectIcon('userDummy')} alt="userImage" onError={imageError.bind(this)} />
    </div>
)

const Name = ({ me, name, showPin, clasName }) => (
    <ToolTips overflow={true} showTooltip={true} position={showPin ? 'left' : "right"} className={clasName} message={me ? 'Me' : name}>
        {me ? 'Me' : name}
    </ToolTips>
)

const PinIcon = ({ userPin, user_id, parentClas }) => (
    <div className={parentClas} onClick={() => userPin(user_id)}>
        <SVG src={selectIcon('pinIcon')} />
    </div>
)

// const checkLastPage = (user, show) => {
//     if (user.length <= show) {
//         return 1;
//     }
//     let page = user.length / show;
//     const remaing = user.length % show;
//     if (remaing > 0) {
//         page += 1;
//     }
//     return page;
// }

// const caloriesASC = (a, b) => {
//     let textA = a['calories_burnt'] || 0;
//     let textB = b['calories_burnt'] || 0;
//     return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
// }
// const caloriesDES = (a, b) => {
//     var textA = a['calories_burnt'] || 0;
//     var textB = b['calories_burnt'] || 0;
//     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
// }

const SortData = (a, b) => {
    var textA = a['me'];
    var textB = b['me'];
    return (textA === textB) ? 0 : textA ? -1 : 1;
}

// const sortDataASC = (a, b) => {
//     var textA = a['first_name'].toLowerCase();
//     var textB = b['first_name'].toLowerCase();
//     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
// }

// const participantSort = (a, b) => {
//     var textA = a['stream'];
//     var textB = b['stream'];
//     return (textA === textB) ? 0 : textA ? -1 : 1;
// }

const checkLimit = (arr, limit, page, zoom) => {
    let temp = [...arr];
    temp.sort(SortData);
    let min = limit * page;
    let max =  limit + (limit * page);
    const sliceArr = temp.slice(min, max);
    temp.splice(limit * page, limit);
    if (zoom) {
        return zoom.zoomRank(temp,min,max,sliceArr);
    }
    return sliceArr;
}

export const checkOngoingLimit = (arr) => {
    // temp.sort(sortDataASC)
    // temp.sort(participantSort)
    // return arr.sort(SortData);
    // return arr.sort(caloriesASC);
    let temp = [...arr];
    temp.sort(SortData);
    // return [...arr,...arr,...arr,...arr,...arr];
    return arr;
}

const BoardContainerDiv = ({ chatRef, memberData, socket, allUserLength, allUsersArr, workoutData, gymData, staffsInActivity, coachData, userActivityData, callback, ...props }) => {
    const count = CheckValue(chatRef)  ? checkCount(chatRef) : 0;
    const [firstTime, setFirstTime] = useState(true);
    const [chatSelect, setChatSelect] = useState(false);
    
    useEffect(() => {
        if(firstTime) {
            setFirstTime(false);
        } else if(CheckValue(callback)) {
            callback(chatSelect);
        }

    },[chatSelect]);
    const countUI = useMemo(() => (
        <div className="messageCount">
            <p>{count > 9 ? '9+' : count}</p>
        </div>
    ),[count]);

    const chatIconUI = useMemo(() => (
        <div className='chatIcon1 active' onClick={() => setChatSelect(true)}>
                    <SVG src={selectIcon('chatIcon1')}></SVG>
                    {!chatSelect && count > 0 ? countUI : null}
                </div>
    ),[chatSelect, count]);
    const memberIcon = useMemo(() => (
        <div className={`memberIcon1${ (CheckValue(chatRef) )?'':' fullWidth'}`} onClick={() => (CheckValue(chatRef) ) ? setChatSelect(false) : null}>
            <SVG src={selectIcon('memberIcon1')}></SVG>
            <span>{allUserLength}</span>
        </div>
    ),[chatRef, allUserLength ]);
    return (
        <HideUnhideDiv callback={callback} clasType={`${chatSelect ? 'chat' : ''}right`} clas={`boardContainers${chatSelect ? ' chat' : ''}`}>
            <div className='tab'>
                {memberIcon}
                {CheckValue(chatRef) ? chatIconUI : null}
            </div>
            {CheckValue(chatRef) ? <Chat ref={chatRef}
                workoutData={workoutData}
                gymName={gymData.name}
                staffsInActivity={staffsInActivity}
                coachData={coachData}
                memberData={memberData}
                userActivityData={userActivityData}
                userId={userActivityData.user_id}
                userSessionId={userActivityData.user_activity_session_id}
                chatUxToggle={chatSelect}
                socket={socket}
            /> : null}
            <div id='member_view_div' className={`member_view_div${chatSelect ? ' hide' : ''}`}>
                {props.children}
            </div>
        </HideUnhideDiv>
    )
}


export const GetOffset = (el) => {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        x += el.offsetLeft - el.scrollLeft;
        y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: y, left: x };
}

export const BoardContainerWaitingRoom = ({ zoomData, allUsers, zoom, userPin, userActivityData, ...props }) => {
    const { allUsersArr, allUserLength } = getAvailableUser(allUsers, 'all');
    const [page, setPage] = useState(1);
    const [firstTime, setFirstTime] = useState(true);
    const limit = 4;
    useEffect(() => {
        if(firstTime) {
            setFirstTime(false);
        } else {
            const temp = document.getElementById('user_container');
            temp.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
    },[page]);

    useEffect(() => {
        if(allUsersArr.length && (page * 4) >  allUsersArr.length ) {
            setPage(Math.ceil(allUsersArr.length/4))
        }

    },[allUsersArr.length, page]);

    const user = checkLimit(allUsersArr, limit, page - 1, zoom).map(el => {
        return <UserUi ondemand={false} key={el.user_id} {...el} userPin={userPin} showPin={true} />
    })

    const hideUnhideCallback = (status) => {
        zoom.stopRenderingParticipantsVideos(status)
    }
    
    const paginationCallback = (newPage) => {
        if(page !== newPage){
            zoom.stopVideosAndClearCanvas()
        }
        setPage(newPage)
    }

    return (
        <BoardContainerDiv {...props} callback={hideUnhideCallback} allUsersArr={allUsersArr} userActivityData={userActivityData} allUserLength={allUserLength} memberData={allUsers[userActivityData.user_id]} >
            <div id="user_container" className='user_container'>
                <canvas id="participants-canvas" className='video-canvas' height={zoomData.height} width={zoomData.width}></canvas>
                {user}
            </div>
            <div className='list_button_container'>
                <Pagination limit={limit} currentPage={page} setPage={paginationCallback} userArrLength={allUserLength} />
            </div>
            {props.children}
        </BoardContainerDiv>
    )
}


export const BoardContainerOngoingRoom = ({ required, allUsers, zoom, userPin, userActivityData, ...props }) => {
    const { allUsersArr, allUserLength } = getAvailableUser(allUsers, 'all');
    const user = allUsersArr.map((el, index) => {
        return <MemberUi key={el.user_id} showPin={true} {...el} ondemand={false} required={required} rank={index + 1} userPin={userPin} calories={el.calories_burnt} />
    })
    return (
        <BoardContainerDiv {...props} allUsersArr={allUsersArr} userActivityData={userActivityData} allUserLength={allUserLength} memberData={allUsers[userActivityData.user_id]} >
            <div className='user_container'>
                {user}
            </div>
            {props.children}
        </BoardContainerDiv>
    )
}
export const BoardContainerOndemandRoom = ({ totalMembers, required, allUsers, userPin, userActivityData, ondemand, ...props }) => {
    const { allUsersArr } = getOndemandAvailableUser(allUsers, 'all');
    // const showPin = allUsersArr.filter(el => el.showStream).length < 5;
    const user = allUsersArr.map((el, index) => {
        return <MemberUi key={el.user_id} {...el} ondemand={ondemand} showPin={CheckAccess('userStream') && el.stream_icon} required={required} rank={el.rank} userPin={userPin} calories={el.calories_burnt} />
    })
    return (
        <BoardContainerDiv {...props} allUsersArr={allUsersArr} userActivityData={userActivityData} allUserLength={totalMembers}  >
            <div className='user_container'>
                {user}
            </div>
            {props.children}
        </BoardContainerDiv>
    )
}

export const Footer = ({ statsClick, required, webPreviewOff, zoom, zoomData, selfData, value, id, ...props }) => {
    const currentHeartRate = selfData.heartrate ? Math.round(selfData.heartrate) : 0;
    const currentHeartRatePer = selfData.max_heart_rate_percent ? Math.round(selfData.max_heart_rate_percent) : 0;
    const [showMore, setShowMore]  = useState(false);

    const moreOption = showMore?<OutsideAlerter clickOutSide={() => {
        setTimeout(() => {
            setShowMore(false);
        }, 200);
    }}>
            <MoreOption  webPreviewOff={() => {
                webPreviewOff();
                setShowMore(false);
            }} />
        </OutsideAlerter>:null;

    const heartRateUI = useMemo(() => <HeartRateUI currentHeartRate={currentHeartRate} currentHeartRatePer={currentHeartRatePer} />,[currentHeartRatePer, currentHeartRate]);

    return (
        <Fragment>
            {moreOption}
            <HideUnhideDiv clasType="ftr" clas={'statsBox_container'} >
                <div className='statsBox'>
                    <React.Fragment>
                        {required.isHeartRateRequired ? heartRateUI : null}
                        <Icon disable={zoomData && !zoomData.sessionStatus} status={zoom && !zoom.getLocalMuteStatus()} icon="audio" onClick={e => statsClick('audio')} />
                        <Icon disable={zoomData && !zoomData.sessionStatus} status={zoom && zoom.getLocalVideoStatus()} icon="video" onClick={e => statsClick('camera')} />
                        <MoreIcon status={showMore} onClick={() => !showMore? setShowMore(!showMore) : null } />
                    </React.Fragment>
                </div>
            </HideUnhideDiv>
        </Fragment>
    )
}

const HeartRateUI = ({ currentHeartRate, currentHeartRatePer }) => (
    <div className={`stats_heartrate`}>
        <SVG className={`stats_heart ${classSelector(currentHeartRatePer)}`} src={selectIcon('heartIcon')} />
        <div className={`stats_number ${classSelector(currentHeartRatePer)}`}>
            <span>{currentHeartRate || '-'}</span> <p>bpm</p>
        </div>
    </div>
)

const MoreOption = ({setStopCoachVideo, webPreviewOff, stopCoachVideo, ...props}) => {
    const [positionX, setPositionX] = useState(0);
    const [positionY, setPositionY] = useState(0);
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [left, setLeft] = useState(0);
    const [top, seTop] = useState(0);
    const [opacity, setOpacity] = useState(0);
    const more_icon = document.getElementById('more_icon');
    useEffect(() => {
        setLeft(positionX - (width - 51));
        seTop(positionY - (height + 15));
    },[positionX,positionY,width,height]);
    const { x, y} = more_icon.getBoundingClientRect();
    if(positionX !== x) {
        setPositionX(x);
    }
    if(positionY !== y) {
        setPositionY(y);
    }
    const moreOption = document.getElementById('moreOption');
    if(CheckValue(moreOption) && !opacity) {
        const { width, height } = moreOption.getBoundingClientRect();
        setWidth(width);
        setHeight(height);
        setOpacity(1);
    }
    return (
        <div id="moreOption" className="moreOption" style={{top:top+'px',left:left+'px',opacity:opacity}}>
            <div className='moreOption_inner'>
                {CheckValue(setStopCoachVideo)?<div className='moreOption_inner_row' onClick={setStopCoachVideo}>
                    <div className={`moreOption_inner_row_svg ${stopCoachVideo?'start':'stop'}`}>
                        <SVG src={selectIcon(stopCoachVideo?'startVideoIcon':'stopVideoIcon')} />
                    </div>
                    <span className='moreOption_inner_row_text'>{stopCoachVideo?'Start':'Stop'} coach video</span>
                </div>:null}
                {CheckValue(webPreviewOff)?<div className='moreOption_inner_row' onClick={webPreviewOff}>
                    <div className='moreOption_inner_row_svg'>
                        <SVG src={selectIcon('stopCasting')} />
                    </div>
                    <span className='moreOption_inner_row_text'>End Casting</span>
                </div>:null}
                {/* <div className='moreOption_inner_row'>
                    <div className='moreOption_inner_row_svg'>
                        <SVG src={selectIcon('stopVideoIcon')} />
                    </div>
                    <span className='moreOption_inner_row_text'>Leave class</span>
                </div>
                <div className='moreOption_inner_row'>
                    <div className='moreOption_inner_row_svg'>
                        <SVG src={selectIcon('stopVideoIcon')} />
                    </div>
                    <span className='moreOption_inner_row_text'>{`Help & Support`}</span>
                </div> */}
            </div>
        </div>
    )
}

const HeartRateUIOngoing = ({ currentHeartRatePer, maxHeartRatePercent, maxHeartRate, currentHeartRate}) => {
    const maxUI = useMemo(() => <StatsNumber value={maxHeartRate} clasValue={maxHeartRatePercent} text='Max' />,[maxHeartRate,maxHeartRatePercent])
    const nowUI = useMemo(() => <StatsNumber value={currentHeartRate} clasValue={currentHeartRatePer} text='Now' />,[currentHeartRate,currentHeartRatePer])
    const svgUI = useMemo(() => <SVG className={`stats_heart ${classSelector(maxHeartRatePercent)}`} src={selectIcon('heartIcon')} />,[maxHeartRatePercent])
    return (
        <div className={`stats_heartrate_ongoing ${classSelector(currentHeartRatePer)}`}>
            {svgUI}
            {maxUI}
            {nowUI}
        </div>
    )
}

const StatsNumber = ({ text, value, clasValue }) => (
    <div className={`stats_number ${classSelector(clasValue)}`}>
        <span>{value || '---'}</span> {text}
    </div>
)

const RankUI = ({ rank, calories_burnt }) => (
    <div className='myRank'>
        <SVG className={`rank_icon`} src={selectIcon('rank_icon')} />
        <span className='rank_number'>{CheckValue(calories_burnt)?rank:'---'}</span>
    </div>
)

export const FooterOngoing = ({ ondemand, stopCoachVideo, setStopCoachVideo, webPreviewOff, statsClick, memberVideos, required, selfData, rank, value, calories_burnt, id, low_latency_mode, zoom, mute_participants_by_default, ...props }) => {
    const [showMore, setShowMore]  = useState(false);
    const maxHeartRatePercent = selfData.maxHeartRatePercent ? Math.round(selfData.maxHeartRatePercent) : 0;
    const maxHeartRate = selfData.maxheartrate ? Math.round(selfData.maxheartrate) : 0;
    const currentHeartRate = selfData.heartrate ? Math.round(selfData.heartrate) : 0;
    const currentHeartRatePer = selfData.max_heart_rate_percent ? Math.round(selfData.max_heart_rate_percent) : 0;
    // const checkClas = `statsBox_container${required.isHeartRateRequired && required.isLeaderBoardRequired ?
    //     ' showHeartAndRank' : required.isHeartRateRequired && !required.isLeaderBoardRequired ? ' showHeartNotRank' : ' hideHeartAndRank'}`;
    const checkClas = `statsBox_container`;
    const moreOption = showMore?<OutsideAlerter clickOutSide={() => {
        setTimeout(() => {
            setShowMore(false);
        }, 200);
    }}>
            <MoreOption stopCoachVideo={stopCoachVideo} webPreviewOff={() => {
                webPreviewOff();
                setShowMore(false);
            }} setStopCoachVideo={() => {
                    setStopCoachVideo(!stopCoachVideo);
                    setShowMore(false);
                }} />
        </OutsideAlerter>:null;
     const status = low_latency_mode && !mute_participants_by_default;
    const caloriesUI = useMemo(() => <Calories calories={selfData.calories_burnt} showCalories={required.isHeartRateRequired} />,[selfData.calories_burnt, required.isHeartRateRequired]);
    const heartRateUIOngoing = useMemo(() => <HeartRateUIOngoing currentHeartRatePer={currentHeartRatePer} maxHeartRatePercent={maxHeartRatePercent} maxHeartRate={maxHeartRate} currentHeartRate={currentHeartRate} />,[currentHeartRatePer, maxHeartRatePercent, maxHeartRate, currentHeartRate]);
    const rankUI = useMemo(() => <RankUI rank={rank} calories_burnt={selfData.calories_burnt} />,[rank, selfData.calories_burnt])
    return (
        <React.Fragment>
            {moreOption}
            <HideUnhideDiv clasType="ftr" clas={checkClas} >
                <div className='statsBox'>
                    {/* {this.state.isHeartRateRequired ? */}
                    <React.Fragment>
                        {required.isHeartRateRequired ? heartRateUIOngoing : null}
                        {caloriesUI}
                        {required.isLeaderBoardRequired ?rankUI: null}
                        {low_latency_mode? 
                            <Icon disable={false} status={zoom && zoom.getLocalVideoStatus()} icon="video" onClick={e => zoom.enableDisableAudioCamera('camera')} />
                            :
                            ((!ondemand ||  CheckAccess('userStream'))?
                                <Icon disable={!CheckValue(memberVideos)} status={memberVideos && memberVideos.getStreamData.isCameraEnabled} icon="video" onClick={e => memberVideos.startStopStream()} />
                            :null)
                        }
                        {status ?
                           <Icon disable={false} status={zoom && !zoom.getLocalMuteStatus()} icon="audio" onClick={e => zoom.enableDisableAudioCamera('audio')} />
                           : null
                        }
                        {ondemand || !low_latency_mode?<Volume icon="volume" value={value} id={id} />:null}
                        {!ondemand?<MoreIcon status={showMore} onClick={() => !showMore? setShowMore(!showMore) : null } />:null}
                    </React.Fragment>
                    {/* :
                    null
                } */}
                </div>
            </HideUnhideDiv>
        </React.Fragment>
    )
}

const Icon = ({ status, icon, onClick, disable, image }) => {
    const newIcon = `${status?'':'no'}${icon}`;
    const imgUi = useMemo(() => <img src={selectIcon(newIcon+'Png')} alt='icon' />,[newIcon]);
    // const svgUi = useMemo(() => <SVG className={icon} src={selectIcon(newIcon)} />,[newIcon,icon]);
    const svgUi = useMemo(() => <SVG
    src={selectIcon(newIcon)}
    className={icon}
    title={icon}
  />,[newIcon,icon]);
    const iconUI = useMemo(() => (
        <div className={`stats_icon ${disable ? ' disable' : status ? ' active' : ''}`} onClick={() => !disable ? onClick(!status) : null}>
            {image? imgUi :svgUi}
        </div>

    ),[image, disable, status ]);
    return (
        <Fragment>
            {iconUI}
        </Fragment>
    )}

const MoreIcon = ({ status, onClick }) => (
    <div id='more_icon' className={`stats_icon ${status ? ' active' : ''}`} onClick={onClick}>
        <SVG className='moreIcon' src={selectIcon(`moreIcon`)} />
    </div>
)

const Volume = ({ icon, id }) => {
    const [mute, setMute] = useState(getDefaultValue().status);
    const checkMute = getAudioVolume(id);
    return (
        <div className={`stats_icon_volume${checkMute ? '' : ' active'}`}>
            <InputRange max={100} min={0} step={1} id={id} setMute={setMute} />
            <div className='stats_icon_volume_iconDiv'>
                <SVG className={icon} src={selectIcon(`${checkMute ? 'no' : ''}${icon}`)} onClick={() => {
                        changeVolume(checkMute ? 100 : 0, id, true, setMute);
                    }} />
            </div>
        </div>
    )
}

export const CurrentUser = ({ speaker, allUsers }) => {
    // let thumb = null;
    let name = '';
    if (allUsers.hasOwnProperty(speaker.user_id) && (CheckValue(speaker.score)?(speaker.score > 0.2):true)) {
        // if (allUsers.hasOwnProperty(speaker.user_id)) {
        // thumb = allUsers[speaker.user_id].profile_pic_thumb || selectIcon('userDummy');
        name = allUsers[speaker.user_id].me ? `You're speaking...` : `${allUsers[speaker.user_id].first_name || ''} is speaking...`;
    }
    const speakerUI = useMemo(() => (
        <div className='speaking_div'>
            {/* <div className='speaking_background'></div>
            <div className='speaking_left'></div> */}
            <div className='speaking'>{name}</div>
            {/* <div className='speaking_right'></div> */}
        </div>
    ),[name]);
    return (
        <Fragment>
            {name.length ? speakerUI : null}
        </Fragment>
    )
}

const playerState = (e, setStartStream, isVideoStarted) => {
    if (e.target.readyState === 4 && !isVideoStarted) {
        setStartStream(true);
    }
}

export const UserUi = ({ me, ondemand, totalSeconds, setStartStream, first_name, user_id, profile_pic_thumb, isVideoStarted, userPin, showPin, stream_icon, ant_media_recording, showStream, showCalories, calories_burnt, ...props }) => {
    useEffect(() => {
        setThemeColor();
    },[])

    const nameUI = useMemo(() => <Name me={me} name={first_name} showPin={showPin} clasName={'name'} />,[first_name]);
    const caloriesUI = useMemo(() => <Calories calories={calories_burnt} showCalories={showCalories} />,[calories_burnt, showCalories]);
    const profilePic = useMemo(() => <ProfilePic imageClas={`video_img${isVideoStarted ? '' : ' show'}`} src={profile_pic_thumb} />,[profile_pic_thumb,isVideoStarted]);
    const videoUi = useMemo(() => <video className={`video_img${isVideoStarted ? ' show' : ''}`} id={user_id+'_video'} autoPlay muted></video>,[user_id,isVideoStarted]);
    const pinIcon = useMemo(() => <PinIcon userPin={userPin} user_id={user_id} parentClas={`userPin${showStream ? ' active' : ''}`} />,[user_id,showStream]);
    return (
        <div id={user_id} className={`user_view${me ? ' me' : ''}${isVideoStarted ? '' : ' noVideo'}`}>
            <div className='view'>
                {showPin && (ondemand?true:!me) ? pinIcon : null}
                {ondemand ?
                    <div className="memberVideo">
                        <video className={`video_img${isVideoStarted ? ' show' : ''}`} id={user_id} muted src={ant_media_recording}
                            onPlay={e => {
                                playerState(e, setStartStream, isVideoStarted);
                            }}
                            onPause={e => {
                                const duration = e.target.duration;
                                const currentTime = e.target.currentTime;
                                if (duration === currentTime) {
                                    setStartStream(false);
                                }
                            }}
                            // onStop={e => console.log(e.target.readyState, 'stop')}
                            onCanPlayThrough={e => {
                                // const duration = e.target.duration;
                                e.target.play();
                                playerState(e, setStartStream, isVideoStarted)
                            }}
                        ></video>
                        {profilePic}
                    </div> :
                    <div className="memberVideo">
                        {/* <canvas style={{ opacity: showVideo ? 1 : 0, display: showVideo ? 'inherit' : 'none' }} id={user_id}></canvas> */}
                        {videoUi}
                        {profilePic}
                    </div>
                }
                <div className='name_calories'>
                    {nameUI}
                    {caloriesUI}
                </div>
            </div>
        </div>
    )
}

const setThemeColor = () => {
    const themeColor = getComputedStyle(document.documentElement).getPropertyValue('--theme-color');
    document.documentElement.style.setProperty('--dynamic-variable', themeColor === 'light' ? '#505050' : '#000000');
}

export const UserUiZoomPinnedView = ({ me, setStartStream, first_name, user_id, profile_pic_thumb, isVideoStarted, ...props }) => {
    useEffect(() => {
        setThemeColor();
    },[])

    const nameUI = useMemo(() => <Name me={me} name={first_name} showPin={false} clasName={'name'} />,[first_name]);
    const memberVideoUI = useMemo(() => <MemberVideoUI isVideoStarted={isVideoStarted} profile_pic_thumb={profile_pic_thumb} user_id={user_id} />,[isVideoStarted, user_id, profile_pic_thumb]);
    const selfUI = useMemo(() => (
        <div className='name_calories'>
            <span>
                { isVideoStarted ? 
                    'Me':
                    <React.Fragment>
                        Click on 
                        <span className='icon_div'>
                            <SVG className="scanIcon" src={selectIcon('video')} />
                        </span>
                        below to see your video here
                    </React.Fragment>
                }
            </span>
        </div>
    ),[isVideoStarted])
    return (
        <div id={user_id} className={me? `myStreamVIdeo ${isVideoStarted?' video':' noVideo'}` : `user_view${isVideoStarted ? '' : ' noVideo'}`}>
            { me ? selfUI : 
                <div className='view'>
                    {memberVideoUI}
                    <div className='name_calories'>
                        {nameUI}
                    </div>
                </div>
            }            
        </div>
    )
}

const MemberVideoUI = ({ isVideoStarted, user_id, profile_pic_thumb }) => {
    const videoUI = useMemo(() => <video className={`video_img${isVideoStarted ? ' show' : ''}`} id={user_id} autoPlay muted></video>,[isVideoStarted, user_id]);
    const imageUI = useMemo(() => <img className={`video_img${isVideoStarted ? '' : ' show'}`} src={(CheckValue(profile_pic_thumb)) ? profile_pic_thumb : selectIcon('userDummy')} alt="userImage" onError={imageError.bind(this)} />,[isVideoStarted, profile_pic_thumb]);
    return (
        <div className="memberVideo">
            {videoUI}
            {imageUI}
        </div>
    )
}

// const checkVideoDisplay = (id) => {
//     const ele = document.getElementById(id);
//     if (ele) {
//         return ele.readyState > 2;
//     }
//     return false;
// }

export const PinVideoView = ({ pinUserVideo, required, ondemand, totalSeconds, streamMember, memberVideos, ...props }) => {
    const userUI = checkOngoingLimit(pinUserVideo).map(el => <UserUi totalSeconds={totalSeconds} setStartStream={(value) => setStartStreamForRecording(el, value)} ondemand={ondemand} key={el.user_id} isVideoStarted={el.stream_icon && el.isVideoStarted} {...el} showPin={false} showCalories={required.isHeartRateRequired && required.isLeaderBoardRequired} />)
    const noState = <Nostate key={'noState'} />;
    return (
        // <div className='pinVideoView'>
        <HideUnhideDiv clasType={`left`} clas={`pinVideoView${pinUserVideo.length ? '' : ' noState'}`}>
            <div className={`myStreamVIdeo ${memberVideos && memberVideos.getStreamData.isCameraEnabled?'video':'noVideo'}`}>
                {streamMember}
                <div className='name_calories'><span>
                {memberVideos && memberVideos.getStreamData.isCameraEnabled?'Me':
                <React.Fragment>
                    Click on 
                    <span className='icon_div'>
                        <SVG className="scanIcon" src={selectIcon('video')} />
                    </span>
                    below to see your video here
                </React.Fragment>
                }
                {/* {memberVideos && memberVideos.getStreamData.isCameraEnabled?'Me':'Click on “this icon” below to see your video here'} */}
                </span></div>
            </div>
            {pinUserVideo.length ? userUI : noState}
        </HideUnhideDiv>
    );
}

const Nostate = memo(() => (
    <div className='pin-noState'>
        <div className='userPin'>
                <SVG src={selectIcon('pinIcon')} />
        </div>
        <p>Pin your friends to see them during the workout</p>
    </div>
))

export const PinVideoViewZoom = ({ pinUserVideos,zoomData, required, zoom, ...props }) => {
    const noState = <Nostate key={'noState'} />
    const limit = 4;
    const user = checkLimit(pinUserVideos, limit, 0, zoom).map(el => {
        return <UserUiZoomPinnedView key={el.user_id} {...el} />
    });
    const hideUnhideCallback = (status) => {
        zoom.stopRenderingParticipantsVideos(status)
    }
    return (
        <HideUnhideDiv  callback={hideUnhideCallback} divid='tagged_container_div_zoom' clasType={`left`} clas={`pin_container_zoom${pinUserVideos.length>1 ? '' : ' noState'}`}>
            <canvas id="participants-canvas" className='video-canvas_ongoing' height={zoomData.height} width={zoomData.width}/>
            {user.length>1 ? user : [user, noState]}
        </HideUnhideDiv>
    );
}

export const PinnedViewZoom = ({ pinUserVideos, required, zoom, ...props }) => {
    const noState = (
      <div className="pin-noState">
        <div className="userPin">
          <SVG src={selectIcon("pinIcon")} />
        </div>
        <p>Pin your friends to see them during the workout</p>
      </div>
    );
    const limit = 4;
    const user = checkLimit(pinUserVideos, limit, 0, zoom).map((el) => {
      return <UserUiZoomPinnedView key={el.user_id} {...el} />;
    });
    const hideUnhideCallback = (status) => {
      zoom.stopRenderingParticipantsVideos(status);
    };
    return (
        <PinnedZoomDiv {...props} callback={hideUnhideCallback} >
            <div id="user_container" className='pinned_user_container'>
            <canvas id="participants-canvas" className='pinned_video-canvas' ></canvas>
                {user.length>1 ? user : [user, noState]}
            </div>
        </PinnedZoomDiv>
    )
}

const PinnedZoomDiv = ({ callback, ...props }) => {
    return (
        <HideUnhideDiv callback={callback} divid='tagged_container_div_zoom' clasType='' clas='zoom_pinned_video_container'>
            <div id='member_view_div' className='pinned_member_view_div'>
                {props.children}
            </div>
        </HideUnhideDiv>
    )
}



const Calories = ({ calories, showCalories }) => {
    return (
        <Fragment>
            {showCalories?
                <div className='calories'>
                    <img className='calories_svg' src={selectIcon('calories_icon1')} alt='calories_icon' />
                    {calories?Math.round(calories) : '---'}
                </div>
            :null}
        </Fragment>
)}
const MemberUi = ({ required, ondemand, total_attempts_yet, showPin, rank, calories, opacity, showStream, userPin, first_name, me, user_status_in_activity, max_heart_rate_percent, profile_pic_thumb, user_id, maxHeartRate, heartrate, stream_icon, ...props }) => {
    const userStatus = checkUserStatus(user_status_in_activity, ondemand);
    document.documentElement.style.setProperty('--dynamic-variable', themeColor.theme === 'light' ? '#505050' : '#000000');
    const caloriesUI = useMemo(() => <Calories calories={calories} showCalories={required.isHeartRateRequired && required.isLeaderBoardRequired} />,[calories, required.isHeartRateRequired && required.isLeaderBoardRequired]);
    const nameUI = useMemo(() => <Name me={me} name={first_name} showPin={true} clasName={''} />,[first_name]);
    const pinIconCond = useMemo(() => <PinIconCond userPin={userPin} user_id={user_id} ondemand={ondemand} me={me} showStream={showStream} userStatus={userStatus} />,[ondemand, me, showStream, userStatus]);
    const videoIcon = useMemo(() => <SVG className='video_icon' src={selectIcon('video_icon')} />)
    const profilePic = useMemo(() => <img src={(CheckValue(profile_pic_thumb)) ? profile_pic_thumb : selectIcon('userDummy')} alt="userImage" onError={imageError.bind(this)} />,[profile_pic_thumb]);
    const rankUI = useMemo(() => <span className='rank'>{calories && rank?rank:'---'}</span>,[calories,rank]);
    const attemptUI = useMemo(() => <span className='attempt'>{` x${total_attempts_yet}`}</span>,[total_attempts_yet]);
    return (
        <div className={`memberUi${me ? ' me' : ''}${userStatus ? '' : ' disable'}`}>
            <div className='memberDetail'>
                {required.isHeartRateRequired && required.isLeaderBoardRequired ? rankUI : null}
                <div className='member_picture'>
                    <CircularProgressbarWithChildren value={heartrate} maxValue={maxHeartRate} strokeWidth={5} styles={{ path: { stroke: textColorSelector(max_heart_rate_percent || 1) }, trail: { stroke: me ? '#FFFFFF' : '#ffffff00' } }}>
                        {profilePic}
                    </CircularProgressbarWithChildren>
                    {stream_icon ? videoIcon : null}
                </div>
                <div className='name'>
                    {nameUI}
                    {CheckValue(total_attempts_yet) && total_attempts_yet > 0 ? attemptUI : null}
                </div>
            </div>
            {showPin ? pinIconCond : null}
            {caloriesUI}
        </div>
    )
}

const PinIconCond = ({ userPin, user_id, ondemand, me, showStream, userStatus }) => (
    <div className={`userPin${me && !ondemand?' hide':''}${showStream ? ' active' : ''}`} onClick={() => userStatus && (ondemand?true:!me) ? userPin(user_id) : null}>
        <SVG src={selectIcon('pinIcon')} />
    </div>
)

export const checkUserStatus = (user_status_in_activity, status) => status || user_status_in_activity === 'WAITING' || user_status_in_activity === 'STARTED';