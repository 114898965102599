import React, { Component } from "react";
import "./Chat.scss";
import { SVG, selectIcon } from "../selector";
import ToolTips from "../ToolTips/ToolTips";
import PropTypes from "prop-types";
import {
  CheckValue,
  message,
  randomString,
  sortDate,
} from "../../../services/CommonFunction";
import { imageError } from "../../../services/subscriberService";
import moment from "moment";
import { staticVar, themeData } from "../../../services/Constants";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showChatDropdown: false,
      userType: "everyone",
      messageText: "",
      messageList: {},
      newMessageCount: localStorage.getItem("messageCount") || 0,
      hasMore: false,
      load: true,
      firstTime: false,
      meta_id: "message_dropdown_list",
    };
  }

  componentDidMount() {
    this.eventListener("addEventListener");
  }

  componentWillUnmount() {
    this.eventListener("removeEventListener");
    message.list = this.state.messageList;
    message.count = this.state.newMessageCount;
    this.socketListner("off");
  }

  eventListener = (status) => {
    document[status]("mousedown", this.handleClickOutside);
  };

  handleClickOutside = (e) => {
    if (
      e.target.getAttribute("meta-id") !== "message_dropdown_list" &&
      this.state.showChatDropdown
    ) {
      this.setState({
        showChatDropdown: false,
      });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.socket &&
      this.props.socket.connected &&
      !this.state.firstTime
    ) {
      this.setState({ firstTime: true });
      this.socketListner("on");
      this.getChat();
    }
    if (this.props.chatUxToggle && this.state.newMessageCount > 0) {
      this.setState({ newMessageCount: 0 });
      localStorage.removeItem("messageCount");
    }
  }

  socketListner = (status) => {
    this.props.socket[status]("message", this.messageReceived);
  };

  getChat = () => {
    const obj = {
      user_id: this.props.userId,
      activity_id: this.props.userActivityData.activity_id,
    };
    this.props.socket.emit("get_chat", obj, (data) => {
      if (data.length) {
        const msg = data.map((el) => JSON.parse(el));
        const { messageList } = this.state;
        msg.forEach((el) => {
          messageList[el.message_id] = { ...el };
        });
        // RemoveDuplicate(messageList, 'message_id')
      }
      this.setState({
        hasMore: data.length && data.length > 19,
        load: false,
      });
    });
  };

  count = () => {
    return this.state.newMessageCount;
  };

  messageReceived = async (data) => {
    const { userId, chatUxToggle } = this.props;
    const { messageList } = this.state;
    if (
      !chatUxToggle &&
      data.sender_id !== userId &&
      messageList[data.message_id] === undefined
    ) {
      await this.setState((prevState) => ({
        newMessageCount: parseInt(prevState.newMessageCount, 10) + 1,
      }));
      localStorage.setItem("messageCount", this.state.newMessageCount);
    }
    messageList[data.message_id] = { ...data };
    this.checkScroll();
    this.setState({
      messageList: { ...messageList },
    });
  };

  sendMessage = () => {
    const { messageText, userType } = this.state;
    const {
      userId,
      memberData,
      coachData,
      userActivityData,
      userSessionId,
      gymName,
      workoutData,
    } = this.props;
    if (messageText === "") {
      return false;
    }
    let messageBody = {
      activity_id: userActivityData.activity_id,
      date: new Date().getTime(),
      message: messageText.trim(),
      message_id: randomString(10) + moment().format().split("+")[0],
      sender_id: userId,
      sender_name: memberData.first_name + " " + memberData.last_name,
      sender_profile_pic: memberData.profile_pic_thumb,
      user_activity_session_id: userSessionId,
    };
    if (userType === "coach2") {
      messageBody["receiver_id"] = coachData.created_by;
      messageBody["receiver_name"] =
        coachData.first_name + " " + coachData.last_name;
      this.send("message_to_coach", messageBody);
    } else if (userType === "owner") {
      messageBody["receiver_id"] = "owner";
      messageBody["receiver_name"] = gymName;
      messageBody["receiver_profile_pic"] = workoutData.logo_thumb;
      this.send("message_to_owner", messageBody);
    } else {
      this.send("message_to_all_members", messageBody);
    }

    this.checkScroll();
    this.setState((prevState) => ({
      messageText: "",
      messageList: {
        ...prevState.messageList,
        [messageBody.message_id]: messageBody,
      },
    }));
  };

  checkScroll = () => {
    const ele = document.getElementById("scrollableDiv");
    if (CheckValue(ele) && ele.scrollTop === 0) {
      setTimeout(() => {
        ele.scrollTop = 0;
      }, 300);
    }
  };

  send = (status, messageBody) => {
    const { socket } = this.props;
    if (socket) {
      socket.emit(status, messageBody, (data) => {});
    }
  };

  handleKeyPress = (e) => {
    if ((e.which === 32 || e.which === 13) && !e.target.value.length) {
      e.preventDefault();
      return;
    }
    if (e.which === 13) {
      this.sendMessage();
    }
  };

  mesChangeHandler = (event) => {
    this.setState({
      messageText: event.target.value,
    });
  };

  selectUser = (userType) => {
    this.setState({
      userType: userType,
      showChatDropdown: false,
    });
    localStorage.setItem("userType", userType);
  };
  showDropdown = () => {
    this.setState((prevState) => ({
      showChatDropdown: !prevState.showChatDropdown,
    }));
  };
  render() {
    const {
      workoutData,
      gymName,
      staffsInActivity,
      coachData,
      userId,
      chatUxToggle,
    } = this.props;
    const coachName = `${coachData.first_name || ""}${
      coachData.last_name ? " " + coachData.last_name : ""
    }`;
    const { showChatDropdown, userType, messageText, messageList, meta_id } =
      this.state;
    const messgae = Object.values(messageList);
    const themeColor = themeData[staticVar.appId];

    const messages = messgae.sort(sortDate).map((el, index) => {
      const senderName = CheckValue(el.receiver_id)
        ? el.receiver_id === userId
          ? "Me"
          : el.receiver_name
        : "Everyone";

      return (
        <div
          key={el.message_id + el.sender_id}
          className={`messageBox ${
            userId === el.sender_id ? "sender" : "reciver"
          }`}
        >
          <div className="messageBox_div">
            <ToolTips
              showTooltip={true}
              overflow={true}
              message={senderName}
              className={`messageTo ${themeColor.theme}`}
            >
              {"@" + senderName}
              <span className="time">
                {new Date(Number(el.date)).toLocaleTimeString(
                  "en-US",
                  staticVar.options
                )}
              </span>
            </ToolTips>
            <div
              className={`messageBox_outer${
                CheckValue(el.receiver_id) ? " personal" : ""
              }`}
            >
              <div className="message">
                <p>{el.message}</p>
                <ToolTips
                  showTooltip={true}
                  overflow={true}
                  message={el.sender_name}
                  className={`senderName`}
                >
                  {"~ " + el.sender_name}
                </ToolTips>
              </div>
            </div>
          </div>
          <img
            className="messageBox_user_profile"
            src={
              el.sender_profile_pic
                ? el.sender_profile_pic
                : selectIcon("userDummy")
            }
            onError={imageError.bind(this)}
            alt="user_profilePic"
          />
        </div>
      );
    });
    return (
      <React.Fragment>
        {chatUxToggle ? (
          <div className="chatListContainer">
            <div id="scrollableDiv" className="chatList">
              {messages}
              {!messgae.length ? (
                <span className={`noMessage ${themeColor.theme}`}>
                  No message yet
                </span>
              ) : null}
            </div>
            <div className="chatBox">
              <input
                maxLength={140}
                value={messageText}
                placeholder="Type Message..."
                type="text"
                onKeyPress={this.handleKeyPress}
                onChange={this.mesChangeHandler}
              />
              <div
                meta-id={meta_id}
                className="selectedUser"
                onClick={this.showDropdown}
              >
                {userType === "everyone" ? (
                  <SVG src={selectIcon(userType)} />
                ) : (
                  <img
                    meta-id={meta_id}
                    src={
                      userType === "owner"
                        ? workoutData.logo_thumb || null
                        : coachData.profile_pic_thumb || null
                    }
                    alt="user_profilePic"
                  />
                )}
              </div>
              <div
                className={`sendButton${messageText.length ? " active" : ""}`}
              >
                <SVG
                  className={messageText.length ? "activeSend" : "inActiveSend"}
                  onClick={this.sendMessage}
                  src={selectIcon("sendMessage")}
                />
              </div>
              {showChatDropdown ? (
                <div meta-id={meta_id} className="chatDropDown">
                  <div
                    meta-id={meta_id}
                    onClick={() => this.selectUser("owner")}
                    className="selUserType borderB"
                  >
                    <img
                      meta-id={meta_id}
                      src={workoutData.logo_thumb || null}
                      alt="gym_logo"
                    />
                    <p meta-id={meta_id}>
                      <ToolTips
                        meta_id={meta_id}
                        showTooltip={true}
                        overflow={true}
                        position="top"
                        message={gymName}
                        className="user-name"
                      >
                        {gymName || ""}
                      </ToolTips>
                    </p>
                  </div>
                  {staffsInActivity[coachData.created_by] ? (
                    <div
                      meta-id={meta_id}
                      onClick={() => this.selectUser("coach2")}
                      className="selUserType borderB"
                    >
                      <img
                        meta-id={meta_id}
                        src={coachData.profile_pic_thumb || null}
                        alt="user_profilePic"
                      />
                      <p meta-id={meta_id}>
                        <ToolTips
                          meta_id={meta_id}
                          showTooltip={true}
                          overflow={true}
                          position="top"
                          message={coachName}
                          className="user-name"
                        >
                          {coachName || ""}
                        </ToolTips>
                      </p>
                    </div>
                  ) : null}
                  <div
                    meta-id={meta_id}
                    onClick={() => this.selectUser("everyone")}
                    className="selUserType"
                  >
                    <img
                      meta-id={meta_id}
                      src={selectIcon("everyone")}
                      alt="user_profilePic"
                    />
                    <p meta-id={meta_id}>Everyone</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

Chat.propTypes = {
  workoutData: PropTypes.object,
  gymName: PropTypes.string,
  staffsInActivity: PropTypes.object,
  coachData: PropTypes.object,
  memberData: PropTypes.object,
  userActivityData: PropTypes.object,
  userId: PropTypes.string,
  userSessionId: PropTypes.string,
  chatUxToggle: PropTypes.bool,
};

Chat.defaultProps = {
  chatUxToggle: false,
  workoutData: {},
  gymName: "",
  staffsInActivity: {},
  userActivityData: {},
  coachData: {},
  memberData: {},
  userId: "",
  userSessionId: "",
};

export default Chat;
