import React, { Component } from "react";
// import './HLS.scss';
import Hls from "hls.js";
import {
  pattern,
  playerPlaySubscriber,
  seekVideoSubscriber,
} from "../../../services/subscriberService";
import PropTypes from "prop-types";
import { CheckValue } from "../../../services/CommonFunction";

// var caches;
class HLS extends Component {
  state = {
    vid: null,
    videoSrc: null,
    hls: null,
    version: "?v=1.0",
  };

  componentDidUpdate = async (nextProps) => {
    const src = this.checkSrc(nextProps.videoSrc);
    if (this.state.videoSrc !== src) {
      let temp = {
        videoSrc: src,
      };
      // this.state.vid.pause();
      if (CheckValue(this.state.hls)) {
        this.eventListner("off", this.state.hls, null);
        this.state.hls.destroy();
        temp["hls"] = null;
        // this.state.hls.loadSource(src);
      }
      this.setState({ ...temp });
      setTimeout(() => {
        this.videoHlr(src);
      }, 1000 * 0.3);
    }
  };

  checkSrc = (src) => {
    if (!CheckValue(src)) {
      return src;
    }
    return typeof src === "string" ? src : src.value;
  };

  componentDidMount = () => {
    this.setState({ vid: document.getElementById(this.props.id) });
    this.seekSubscription = seekVideoSubscriber.get().subscribe((data) => {
      if (CheckValue(this.props.currentData.video)) {
        const { play_at } = this.props.currentData.video;
        if (data.data > play_at && CheckValue(this.state.vid)) {
          const duration = this.state.vid.duration;
          let setTime = data.data - play_at;
          if (setTime > duration) {
            setTime = setTime / duration;
          }
          this.state.vid.currentTime = setTime;
        }
      }
    });
    this.playerPlaySubscription = playerPlaySubscriber
      .get()
      .subscribe((data) => {
        if (data.data) {
          this.state.vid.play();
        } else {
          if (!this.state.isPausedOnce) {
            this.setState({ isPausedOnce: true });
          }
          this.state.vid.pause();
        }
      });
  };

  componentWillUnmount() {
    this.seekSubscription.unsubscribe();
    this.playerPlaySubscription.unsubscribe();
    if (CheckValue(this.state.hls)) {
      this.eventListner("off", this.state.hls);
    }
    this.state.hls.destroy();
    if (this.props.isOndemand) {
      localStorage.setItem("videoNow", true);
    }
  }

  videoHlr = async (videoSrc, close = false) => {
    const obj = new RegExp(pattern.streamUrl);
    if (Hls.isSupported() && obj.test(videoSrc)) {
      const hls = new Hls();
      if (CheckValue(this.state.vid)) {
        hls.attachMedia(this.state.vid);
        this.setState({ hls: hls });
        this.eventListner("on", hls, videoSrc);
        // } else {
        //     this.videoHlr(videoSrc,close);
      }
    } else if (this.state.vid.canPlayType("application/vnd.apple.mpegurl")) {
      this.state.vid.addEventListener("loadedmetadata", function () {
        //  video.play();
      });
    }
  };

  eventListner = (status, hls, videoSrc = null) => {
    const { version } = this.state;
    const { id, addVersion } = this.props;
    const video = addVersion ? videoSrc : videoSrc + version;
    if (CheckValue(hls)) {
      hls[status](Hls.Events.MEDIA_ATTACHED, function () {
        hls.loadSource(video);
        // console.log('MEDIA_ATTACHED',id);
        hls[status](Hls.Events.MANIFEST_PARSED, function () {
          hls.startLoad(1);
          this.state.vid.play();
        });
      });

      hls[status](Hls.Events.LEVEL_LOADED, function (event, data) {});
      hls[status](Hls.Events.ERROR, function (event, data) {
        console.info("error", id, data);
      });
    }
  };

  render() {
    const { id, src, addVersion, controls, loop, canPlay, timeUpdate } =
      this.props;
    const { videoSrc, version } = this.state;
    let videoS = null;
    if (CheckValue(videoSrc)) {
      videoS = addVersion ? videoSrc + version : videoSrc;
    }
    return (
      <video
        id={id}
        preload="auto"
        src={videoS}
        loop={loop}
        muted
        autoPlay
        controls={controls}
        onPlay={(e) => {
          // if (localStorage.getItem('videoPausedOnce') === 'false' && localStorage.getItem('videoPaused') === "PAUSE") {
          //     e.target.pause();
          // }
        }}
        onCanPlay={canPlay}
        onTimeUpdate={timeUpdate}
        onPause={(e) => {}}
        onLoadedMetadata={(e) => {}}
        onLoadStart={(e) => {}}
        onSeeked={(e) => {}}
        poster={src}
      />
    );
  }
}

export default HLS;

HLS.propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string,
  videoSrc: PropTypes.string,
  controls: PropTypes.bool,
  loop: PropTypes.bool,
  timeUpdate: PropTypes.func,
  canPlay: PropTypes.func,
};

HLS.defaultProps = {
  id: "videoId",
  src: null,
  videoSrc: null,
  controls: false,
  loop: false,
  timeUpdate: (e) => null,
  canPlay: (e) => null,
};
