import React, { Component, Fragment } from "react";
import ZoomVideo, { ConnectionState, ReconnectReason } from "@zoom/videosdk";
import {
  getMediaPermission,
  CheckValue,
  checkMemberVideoPermission,
  participantVisibility,
} from "../../../services/CommonFunction";
import { ErrorUI } from "../../../components/UI/Modals/Modals";
import { changeVolume } from "../../../components/UI/InputRange/InputRange";
import { messageService } from "../../../services/subscriberService";
import { staticVar } from "../../../services/Constants";

class ZoomSDK extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clas: props.clas,
      zmClient: ZoomVideo.createClient(),
      stream: null,
      canvas: null,
      temp: [],
      isUpdatingCanvas: false,
      deviceId: null,
      userId: {},
      showUser: {},
      audioDecode: false,
      audioEncode: false,
      videoDecode: false,
      videoEncode: false,
      isLocalStreamStarted: false,
      isWindowResizingStopped: false,
      isWindowResizing: false,
      isParticipantsCanvasHidden: false,
      isCoachVideoStarted: false,
      coachZoomId: null,
      activeSpeaker: null,
      OvCameraId: null, // current selected camera id
      OvAudioId: null, // current selected audio id
      width: 217,
      height: 453,
    };
  }

  zoomState(obj) {
    this.setState(obj);
  }

  componentDidMount() {
    // const galleryView = document.getElementById('coach_canvas_view');
    this.state.zmClient.init({
      debug: false,
      // zoomAppRoot: galleryView[0],
      language: "en-US",
      customize: {
        video: {
          isResizable: true,
          // viewSizes: {
          //     default: { width: galleryView[0].clientWidth, height: galleryView[0].clientHeight },
          //     // ribbon: { width: node.clientWidth / 4, height: node.clientHeight },
          // },
        },
      },
    });
    this.getPermission();

    if (CheckValue(localStorage.getItem("setVolume"))) {
      changeVolume(
        parseInt(localStorage.getItem("setVolume"), 10),
        this.props.id
      );
    } else {
      changeVolume(100, this.props.id);
    }
    this.eventListener("addEventListener");
  }

  getPermission = () => {
    getMediaPermission({ audio: true, video: true }, null, 0, false)
      .then(async (res) => {
        if (res.status) {
          this.joinMetting(this.props.video_conference_data);
        } else {
          ErrorUI(
            "Kindly allow access to your camera and microphone in your settings and try again."
          );
        }
      })
      .catch((err) => {
        console.info(err);
      });
  };
  eventListener = (status) => {
    window[status]("resize", this.displayWindowSize);
  };

  componentWillUnmount() {
    this.eventListener("removeEventListener");
    this.leave();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      CheckValue(prevProps.isOngoingRoom) &&
      this.props.isOngoingRoom !== prevProps.isOngoingRoom &&
      this.state.isCoachVideoStarted
    ) {
      this.stopCoachVideo(this.state.coachZoomId);
      setTimeout(() => {
        this.startCoachVideo(this.state.coachZoomId);
      }, 1000 * 1);
    }
  }

  displayWindowSize = () => {
    // Get width and height of the window excluding scrollbars
    clearTimeout(this.resizeFuncId);
    if (!this.state.isWindowResizing) {
      this.setState({ isWindowResizing: true });
    }
    this.resizeFuncId = setTimeout(() => {
      this.setState({ isWindowResizingStopped: true });
    }, 1000);
  };

  get getZoomData() {
    const { stream } = this.state;
    return {
      ...this.state,
      sessionStatus: CheckValue(stream),
      isCameraEnabled: CheckValue(stream) ? this.getLocalVideoStatus() : false,
      isAudioEnabled: CheckValue(stream) ? !stream.isAudioMuted() : false,
    };
  }

  joinMetting = (data) => {
    const { zmClient } = this.state;
    const video_conference_data =
      this.props.userActivityData.video_conference_object;
    messageService.sendMessage(true);
    let temp = JSON.stringify({
      user_id: this.props.userActivityData.user_id,
      timestamp: +new Date(),
    });

    zmClient
      .join(
        video_conference_data.sessionId,
        video_conference_data.signature,
        temp,
        ""
      )
      .then(async () => {
        this.setState({ stream: zmClient.getMediaStream() });
        messageService.sendMessage(false);
        this.setState({ isWindowResizing: true });
        this.addMeetingEvents("on");
        setTimeout(() => {
          zmClient.getAllUser().forEach((user) => {
            this.userAdd([{ ...user }]);
          });
        }, 1000);
        setTimeout(async () => {
          this.setState({ isWindowResizingStopped: true });
        }, 1000);
      })
      .catch((error) => {
        messageService.sendMessage(false);
        console.info(error);
      });
  };

  get getAudioDeviceList() {
    const list = this.state.stream
      .getMicList()
      .filter((el) => el.deviceId !== "default");
    return {
      list,
      firstDevice: list.length ? list[0].deviceId : "",
    };
  }

  get getSpeakerDeviceList() {
    const list = this.state.stream.getSpeakerList();
    return {
      list,
      firstDevice: list.length ? list[0].deviceId : "",
    };
  }

  get getVideoDeviceList() {
    const list = this.state.stream.getCameraList().map((el) => {
      if (el.label.includes(staticVar.defaultCameraName)) {
        el.label = staticVar.defaultCameraName;
      }
      return el;
    });
    return {
      list,
      firstDevice: list.length ? list[0].deviceId : "",
    };
  }

  connectionListner = (payload) => {
    if (payload.state === ConnectionState.Reconnecting) {
      const { reason, subsessionName } = payload;
      if (reason === ReconnectReason.Failover) {
        console.info("Session Disconnected,Try to reconnect");
      } else if (
        reason === ReconnectReason.JoinSubsession ||
        reason === ReconnectReason.MoveToSubsession
      ) {
        console.info(`Joining ${subsessionName}...`);
      } else if (reason === ReconnectReason.BackToMainSession) {
        console.info("Returning to Main Session...");
      }
    } else if (payload.state === ConnectionState.Connected) {
      console.info("connected");
    } else if (payload.state === ConnectionState.Closed) {
      console.info("closed");
      // setStatus('closed');
      // dispatch({ type: 'reset-media' });
      if (payload.reason === "ended by host") {
        console.info("ended by host");
      }
    }
  };

  mediaSdkChangeListner = (payload) => {
    const { stream } = this.state;
    const { action, type, result } = payload;
    if ((type === "audio" || type === "video") && result === "success") {
      if (action === "encode") {
        this.setState({ [`${type}Encode`]: true });
      } else if (action === "decode") {
        this.setState({ [`${type}Decode`]: true });
      }
    }
    setTimeout(() => {
      if (
        type === "video" &&
        this.state.videoEncode &&
        this.state.videoDecode &&
        CheckValue(localStorage.getItem("isCameraEnable")) &&
        !this.getLocalVideoStatus()
      ) {
        this.enableDisableAudioCamera("camera");
      }
      if (
        type === "audio" &&
        this.state.audioDecode &&
        this.state.audioEncode
      ) {
        const mediaAudio = document.getElementById("mediaAudio");
        const mediaVideo = document.getElementById("mediaVideo");
        if (CheckValue(mediaVideo) && !mediaVideo.muted) {
          mediaVideo.volume = 1;
        }
        if (CheckValue(mediaAudio) && !mediaAudio.muted) {
          mediaAudio.volume = 1;
        }
        stream
          .startAudio()
          .then((result) => {
            if (!CheckValue(localStorage.getItem("isAudioEnable"))) {
              stream.muteAudio();
            }
          })
          .catch((error) => {
            stream.muteAudio();
            console.info(error);
          });
      }
    }, 1000);
  };

  addMeetingEvents(status) {
    const { zmClient } = this.state;
    // Occurs when new participant join the meeting
    zmClient[status]("user-added", this.userAddListener);
    zmClient[status]("connection-change", this.connectionListner);
    zmClient[status]("share-audio-changed", (payload) => {
      console.info("share-audio-changed", payload);
    });
    zmClient[status]("user-updated", (payload) => {
      if (payload.length) {
        const { allUsers } = this.props;
        const { userId, activeSpeaker } = this.state;
        payload.forEach((el) => {
          const id = userId[el.userId];
          if (allUsers.hasOwnProperty(id) && CheckValue(el.muted)) {
            allUsers[id].muted = el.muted;
            if (
              el.muted &&
              CheckValue(activeSpeaker) &&
              activeSpeaker.userId === el.userId
            ) {
              this.setState({ activeSpeaker: null });
            }
          }
        });
      }
    });

    // Occurs when the participants leave the meeting
    zmClient[status]("user-removed", this.userRemoveListener);
    zmClient[status]("video-dimension-change", (payload) => {
      //   console.info("video-dimension-change", payload);
    });
    zmClient[status]("video-capturing-change", this.videoCaptureChangeListener);
    zmClient[status]("share-audio-change", (payload) => {
      //   console.info("share-audio-change", payload);
    });
    zmClient[status]("main-session-user-updated", (payload) => {
      //   console.info("main-session-user-updated", payload);
    });
    zmClient[status]("video-detailed-data-change", (payload) => {
      //   console.info("video-detailed-data-change", payload);
    });

    zmClient[status]("peer-video-state-change", this.videoStateChangeListener);
    zmClient[status]("auto-play-audio-failed", () => {
      //   console.info("auto play failed, waiting for a user interaction");
    });
    zmClient[status]("audio-statistic-data-change", (payload) => {
      //   console.info("emit", payload);
    });

    zmClient[status]("media-sdk-change", this.mediaSdkChangeListner);
    zmClient[status]("device-change", () => {});
    zmClient[status]("active-speaker", (payload) => {
      if (payload.length && CheckValue(payload[0].displayName)) {
        const id = JSON.parse(payload[0].displayName).user_id.split("_");
        if (
          id.length < 2 &&
          (!CheckValue(this.state.activeSpeaker) ||
            this.state.activeSpeaker.userId !== payload[0].userId)
        ) {
          this.setState((prevState) => ({
            activeSpeaker: {
              userId: payload[0].userId,
              user_id: id[0],
            },
          }));
          // setTimeout(() => {
          //   this.setState({ activeSpeaker: null });
          // }, 1000 * 2);
        }
      }
    });
  }

  userAddListener = (payload) => {
    this.userAdd(payload, true);
  };
  isJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return {
        user_id: str,
        timestamp: +new Date(),
      };
    }
    return JSON.parse(str);
  };

  userAdd = (payload, status = false) => {
    const { userId } = this.state;
    const { allUsers, coachData } = this.props;
    payload.forEach((el) => {
      const data = this.isJson(el.displayName);
      const id = data.user_id.split("_");
      // const id = el.displayName.split('_');
      if (allUsers.hasOwnProperty(id[0])) {
        if (CheckValue(allUsers[id[0]].timestamp)) {
          if (data.timestamp < allUsers[id[0]].timestamp) {
            return;
          }
        }
        allUsers[id[0]].timestamp = data.timestamp;
        allUsers[id[0]].userId = el.userId;
        allUsers[id[0]]["stream_icon"] = el.bVideoOn;
        // allUsers[id[0]]['muted'] = el.muted;
      } else if (coachData.user_id === id[0]) {
        let key = id[1] + "_timestamp";
        if (CheckValue(coachData[key])) {
          if (data.timestamp < coachData[key]) {
            return;
          }
        }

        coachData[key] = data.timestamp;
        if (el.bVideoOn) {
          this.startCoachVideo(el.userId);
        }
      } else if (coachData.waitingRoom_open_by === id[0]) {
        let key = id[1] + "_owner_timestamp";
        if (CheckValue(coachData[key])) {
          if (data.timestamp < coachData[key]) {
            return;
          }
        }

        coachData[key] = data.timestamp;
        if (el.bVideoOn) {
          this.startCoachVideo(el.userId);
        }
      }
      if (!userId.hasOwnProperty(id[0])) {
        userId[el.userId] = id[0];
      }
    });
  };

  userRemoveListener = (payload) => {
    const { userId } = this.state;
    const { allUsers, coachData } = this.props;
    payload.forEach((el) => {
      if (userId.hasOwnProperty(el.userId)) {
        const id = userId[el.userId];
        if (allUsers.hasOwnProperty(id)) {
          if (allUsers[id].userId === el.userId) {
            if (allUsers[id].isVideoStarted) {
              this.stopAttendeeVideo(el.userId);
            }
            delete allUsers[id].userId;
          }
        } else if (
          coachData.userId === el.userId ||
          coachData.ownerUserId === el.userId
        ) {
          this.stopCoachVideo(el.userId);
        }
        delete userId[el.userId];
      }
    });
  };

  videoStateChangeListener = (payload) => {
    const { allUsers, coachData } = this.props;
    const { userId } = this.state;
    if (userId.hasOwnProperty(payload.userId)) {
      const id = userId[payload.userId];
      if (allUsers.hasOwnProperty(id)) {
        if (payload.action === "Stop" && allUsers[id].isVideoStarted) {
          this.stopAttendeeVideo(payload.userId);
        }
        console.log(allUsers[id]);
        if (
          this.props.isOngoingRoom &&
          !checkMemberVideoPermission(
            allUsers[id].participant_video_visibility_in_attempt
          )
        ) {
          return;
        }

        allUsers[id]["stream_icon"] = payload.action === "Start";
      } else if (
        coachData.user_id === id ||
        coachData.waitingRoom_open_by === id
      ) {
        if (payload.action === "Start") {
          this.startCoachVideo(payload.userId);
        } else {
          this.stopCoachVideo(payload.userId);
        }
      } else if (payload.action === "Stop") {
        this.stopAttendeeVideo(payload.userId);
      }
    }
  };

  videoCaptureChangeListener = (payload) => {
    const { allUsers } = this.props;
    const { userId, zmClient } = this.state;
    const userInfo = zmClient.getCurrentUserInfo();
    if (!CheckValue(userInfo)) {
      return;
    }
    const zoomUserId = userInfo.userId;
    if (userId.hasOwnProperty(zoomUserId)) {
      const id = userId[zoomUserId];
      console.log(allUsers[id]);
      if (allUsers[id].me || participantVisibility(allUsers[id])) {
        allUsers[id]["stream_icon"] = payload.state === "Started";
        if (payload.state === "Stopped" && allUsers[id].isVideoStarted) {
          this.stopAttendeeVideo(payload.userId);
        }
      }
    }
  };

  startAttendeeVideo = (zoomUserId, index) => {
    const { stream, userId } = this.state;
    const { allUsers } = this.props;
    const id = userId[zoomUserId];
    console.log(zoomUserId, userId[zoomUserId]);
    console.log(id, allUsers[id]);
    if (
      CheckValue(stream) &&
      allUsers.hasOwnProperty(id) &&
      (allUsers[id].me || participantVisibility(allUsers[id])) &&
      !allUsers[id].isVideoStarted
    ) {
      const userDiv = document.getElementById(id);
      if (CheckValue(userDiv)) {
        const { finalWidth, finalheight, x, y, status } = this.getDimension(
          id,
          index
        );
        if (!status) {
          return;
        }
        const canvas = document.querySelector("#participants-canvas");
        stream
          .renderVideo(
            canvas,
            zoomUserId,
            finalWidth,
            finalheight,
            x,
            y,
            1,
            zoomUserId
          )
          .then((res) => {
            allUsers[id].isVideoStarted = true;
          })
          .catch((err) => {
            console.info("error", err);
            if (err.reason === "user is not send video") {
              allUsers[id].stream_icon = false;
              allUsers[id].isVideoStarted = false;
            }
          });
      }
    }
  };

  stopAttendeeVideo = (zoomUserId, status = true) => {
    const { allUsers } = this.props;
    const { stream, userId } = this.state;
    const id = userId[zoomUserId];
    if (CheckValue(zoomUserId)) {
      if (CheckValue(stream)) {
        stream
          .stopRenderVideo(
            document.querySelector("#participants-canvas"),
            zoomUserId,
            zoomUserId
          )
          .then((res) => {
            if (
              CheckValue(id) &&
              allUsers.hasOwnProperty(id) &&
              allUsers[id].isVideoStarted
            ) {
              allUsers[id].isVideoStarted = false;
            }
          })
          .catch((err) => {
            console.info("error", err);
            this.stopAttendeeVideo(zoomUserId);
          });
      } else {
        if (
          CheckValue(id) &&
          allUsers.hasOwnProperty(id) &&
          allUsers[id].isVideoStarted
        ) {
          allUsers[id].isVideoStarted = false;
        }
      }
    }
  };

  updateAttendeeVideo = (zoomUserId, index) => {
    const { stream, userId } = this.state;
    const { allUsers } = this.props;
    const id = userId[zoomUserId];

    if (
      CheckValue(stream) &&
      allUsers.hasOwnProperty(id) &&
      allUsers[id].isVideoStarted
    ) {
      const userDiv = document.getElementById(id);
      if (CheckValue(userDiv)) {
        const canvas = document.querySelector("#participants-canvas");
        const { finalWidth, finalheight, x, y, status } = this.getDimension(
          id,
          index
        );
        if (!status) {
          return;
        }
        stream
          .adjustRenderedVideoPosition(
            canvas,
            zoomUserId,
            finalWidth,
            finalheight,
            x,
            y,
            zoomUserId
          )
          .then((res) => {})
          .catch((err) => {
            // this.updateAttendeeVideo(zoomUserId,index);
            this.startAttendeeVideo(zoomUserId, index);
            console.info("error", err);
          });
      }
    }
  };

  getDimension = (id, index) => {
    // index = 1;
    const { activity_status } = this.props;
    const { isWindowResizing, isUpdatingCanvas } = this.state;
    if (isWindowResizing || isUpdatingCanvas) {
      return {
        finalWidth: 0,
        finalheight: 0,
        x: 0,
        y: 0,
        status: false,
      };
    }
    if (activity_status === staticVar.waitingRoom || true) {
      const userDiv = document.getElementById(id);
      const canvas = document.querySelector("#participants-canvas");
      const { width, height } = canvas.getBoundingClientRect();
      const userDivData = userDiv.getBoundingClientRect();
      let status = true;
      const finalheight = Math.round(userDivData.height);
      const finalWidth = Math.round(userDivData.width) - 4;
      const x = 2;
      let gap = 10;
      if (activity_status !== staticVar.waitingRoom) {
        gap = 11;
      }
      const y = height - (index * (finalheight + gap) + finalheight);
      if (y < 0 || height < y + finalheight) {
        if (!isUpdatingCanvas) {
          this.setState({ isWindowResizing: true });
          setTimeout(() => {
            this.setState({ isWindowResizingStopped: true });
          }, 1000);
          return {
            finalWidth: 0,
            finalheight: 0,
            x: 0,
            y: 0,
            status: false,
          };
        }
      }
      const widthRatio = 1 || 1920 / width;
      const heightRatio = 1 || 1080 / height;
      const newX = Math.round(x * heightRatio);
      let newY = Math.round(y * widthRatio);

      if (newX < 0 || newY < 0) {
        status = false;
      }
      return {
        finalWidth: Math.round(finalWidth * widthRatio),
        finalheight: Math.round(finalheight * heightRatio),
        x: newX < 0 ? 0 : newX,
        y: newY < 0 ? 0 : newY,
        status,
      };
    }
  };

  startStopCoachVideo(status) {
    const { coachZoomId } = this.state;
    if (CheckValue(coachZoomId)) {
      if (status) {
        this.startCoachVideo(coachZoomId);
      } else {
        this.stopCoachVideo(coachZoomId);
      }
    }
  }

  stopRenderingParticipantsVideos(status) {
    if (status) {
      this.setState({ isParticipantsCanvasHidden: status });
      this.stopVideosAndClearCanvas();
    } else {
      setTimeout(() => {
        this.setState({ isParticipantsCanvasHidden: status });
      }, 300);
    }
  }

  stopVideosAndClearCanvas() {
    const { showUser } = this.state;
    if (Object.values(showUser).length) {
      Object.values({ ...showUser }).forEach((el, index) => {
        if (el.data.isVideoStarted) {
          this.stopAttendeeVideo(el.data.userId);
        }
        delete showUser[el.data.user_id];
      });
    }
    this.clearVideoCanvas();
  }

  startCoachVideo = (id) => {
    const { stream } = this.state;
    if (CheckValue(stream)) {
      const coachCanvas = document.querySelector("#coach_canvas_view");
      stream
        .renderVideo(coachCanvas, id, 1920, 1080, 0, 0, 3)
        .then((res) => {
          this.setState({ coachZoomId: id, isCoachVideoStarted: true });
        })
        .catch((err) => {
          console.info("start-video-error:- ", err);
          setTimeout(() => {
            this.startCoachVideo(id);
          }, 3 * 1000);
        });
    }
  };

  stopCoachVideo = (id) => {
    const { stream } = this.state;
    if (!CheckValue(id) || !CheckValue(stream)) {
      return;
    }
    const coachCanvas = document.querySelector("#coach_canvas_view");
    stream.stopRenderVideo(coachCanvas, id);
    this.setState({ isCoachVideoStarted: false });
  };

  stopStreming = () => {
    const { stream } = this.state;
    stream
      .stopVideo()
      .then((result) => {
        const userInfo = this.state.zmClient.getCurrentUserInfo();
        const zoomUserId = userInfo.userId;
        this.stopAttendeeVideo(zoomUserId);
        localStorage.removeItem("isCameraEnable");
        if (this.props.activity_status === staticVar.roomStarted) {
          const obj = { ...this.props.userActivityData };
          if (this.props.pushSocket) {
            this.props.socket.emit("publish_stream_stopped", obj, (data) => {});
          }
        }
      })
      .catch((err) => {
        console.info("stop-streaming-error:- ", err);
      });
  };

  stopAudio = () => {
    const { stream } = this.state;
    if (CheckValue(stream) && !stream.isAudioMuted()) {
      stream.muteAudio();
    }
    localStorage.removeItem("isAudioEnable");
  };

  // setDeviceId = async (camera_id, audio_id, status = true) => {
  //   const { stream } = this.state;
  //   if (!CheckValue(camera_id) || !camera_id.length) {
  //     camera_id = this.getVideoDeviceList.firstDevice;
  //   }
  //   if (!CheckValue(audio_id) || !audio_id.length) {
  //     audio_id = this.getAudioDeviceList.firstDevice;
  //   }
  //   await this.setState({
  //     OvCameraId: camera_id,
  //     OvAudioId: audio_id,
  //     isLocalStreamStarted: true,
  //   });
  //   localStorage.setItem("OvCameraId", camera_id);
  //   localStorage.setItem("OvAudioId", audio_id);
  //   if (CheckValue(stream)) {
  //     stream.switchCamera(camera_id);
  //     stream.switchMicrophone(audio_id);

  //     setTimeout(() => {
  //       if (
  //         !this.getLocalVideoStatus() &&
  //         (status || CheckValue(localStorage.getItem("isCameraEnable")))
  //       ) {
  //         this.enableDisableAudioCamera("camera");
  //       }
  //     }, 300);
  //   }
  // };

  enableDisableAudioCamera = async (data) => {
    const { stream, zmClient } = this.state;
    const userInfo = zmClient.getCurrentUserInfo();
    if (CheckValue(stream) && CheckValue(userInfo)) {
      if (data === "camera") {
        if (this.getLocalVideoStatus()) {
          console.log('call-enableDisableAudioCamera');
          this.stopStreming();
          this.hideShowMyVideoCard(false);
        } else {
          let data = {
            mirrored: false,
            hd: false,
          };
          stream
            .startVideo(data)
            .then(() => {
              localStorage.setItem("isCameraEnable", true);
              this.hideShowMyVideoCard(true);
            })
            .catch((err) => {
              console.info("error-", err);
            });
        }
      } else if (data === "audio") {
        if (stream.isAudioMuted()) {
          stream.unmuteAudio();
          localStorage.setItem("isAudioEnable", "true");
        } else {
          this.stopAudio();
          localStorage.removeItem("isUnmuteEnabled");
        }
      }
    }
  };

  muteByDeault = async () => {
    const { stream } = this.state;
    if (CheckValue(stream)) {
      stream.muteAudio();
    }
  };

  hideShowMyVideoCard = (videoStarted) => {
    const { activity_status } = this.props;
    if (
      !CheckValue(activity_status) ||
      activity_status !== staticVar.roomStarted
    ) {
      return;
    }
    const obj = { ...this.props.userActivityData };
    if (videoStarted) {
      this.props.socket.emit("publish_stream_started", obj, (data) => {});
    } else {
      this.props.socket.emit("publish_stream_stopped", obj, (data) => {});
    }
  };

  getLocalVideoStatus = () => {
    const { stream } = this.state;
    if (CheckValue(stream)) {
      return stream.isCapturingVideo();
    }
    return false;
  };

  getLocalMuteStatus = () => {
    const { stream } = this.state;
    if (CheckValue(stream)) {
      return stream.isAudioMuted();
    }
    return true;
  };

  getCoachVideoStatus = () => {
    const { isCoachVideoStarted } = this.state;
    if (CheckValue(isCoachVideoStarted)) {
      return isCoachVideoStarted;
    }
    return false;
  };

  // offVideoAudio = async () => {
  //   const { stream } = this.state;
  //   if (CheckValue(stream)) {
  //     if (this.getLocalVideoStatus()) {
  //       this.stopStreming();
  //       // Stop video input. If the previously chosen camera has an LED light on,
  //       // it will turn off indicating the camera is no longer capturing.
  //     }
  //     this.stopAudio();
  //   }
  // };

  // leaveWRSession = async () => {
  //   const { stream, zmClient } = this.state;
  //   localStorage.removeItem("isStreamPublished");
  //   this.clearVideoCanvas();
  //   if (CheckValue(stream) && this.getLocalVideoStatus()) {
  //     // Stop video input. If the previously chosen camera has an LED light on,
  //     // it will turn off indicating the camera is no longer capturing.
  //     this.stopStreming();
  //   }
  //   if (CheckValue(zmClient)) {
  //     await this.addMeetingEvents("off");
  //     zmClient.leave();
  //   }
  // };

  leave = () => {
    const { zmClient } = this.state;
    if (CheckValue(zmClient)) {
      this.addMeetingEvents("off");
      zmClient.leave();
    }
  };

  hostChange = async () => {
    const { stream } = this.state;
    if (CheckValue(stream)) {
      if (
        CheckValue(localStorage.getItem("isCameraEnable")) ||
        this.getLocalVideoStatus()
      ) {
        console.log('call-hostChange');
        this.stopStreming();
      }
      this.stopAudio();
    }
  };

  increaseLength = () => {
    this.setState((prevState) => ({ temp: [...prevState.temp, 1] }));
  };

  updateCanvas = (fromwhere) => {
    this.updatedCanvas(fromwhere);
  };

  updatedCanvas = (fromwhere) => {
    const { stream } = this.state;
    if (CheckValue(stream)) {
      let canvas = document.querySelector("#participants-canvas");
      let userDiv = document.getElementById("user_container");
      let temp = false;
      if (!CheckValue(userDiv)) {
        userDiv = document.getElementById("tagged_container_div_zoom");
        temp = true;
      }
      if (CheckValue(userDiv)) {
        let clientWidth = userDiv.clientWidth;
        const clientHeight = userDiv.scrollHeight;
        if (temp) {
          clientWidth = clientWidth - 30;
        }
        stream
          .updateVideoCanvasDimension(canvas, clientWidth, clientHeight)
          .then((result) => {
            this.setState({
              width: clientWidth,
              height: clientHeight,
            });
            this.canvasUpdated();
          })
          .catch((error) => {
            console.info("OR cans update failed ", error);
          });
      } else {
        this.canvasUpdated();
      }
    } else {
      this.canvasUpdated();
    }
  };

  canvasUpdated = () => {
    setTimeout(() => {
      this.setState({ isUpdatingCanvas: false });
      // const canvas = document.querySelector('#participants-canvas');
      // const { width,height } = canvas.getBoundingClientRect();
      Object.values(this.state.showUser).forEach((el) => {
        if (el.data.stream_icon && el.data.isVideoStarted) {
          this.updateAttendeeVideo(el.data.userId, el.index);
        } else if (el.data.stream_icon && !el.data.isVideoStarted) {
          this.startAttendeeVideo(el.data.userId, el.index);
        } else if (!el.data.stream_icon && el.data.isVideoStarted) {
          this.stopAttendeeVideo(el.userId);
        }
      });
    }, 1000);
  };

  clearVideoCanvas = () => {
    const { stream } = this.state;
    if (CheckValue(stream)) {
      const canvas = document.querySelector("#participants-canvas");
      stream
        .clearVideoCanvas(canvas)
        .then((res) => {})
        .catch((err) => {
          console.info(err);
        });
    }
  };

  zoomRank = (temp, min, max, sliceArr, resize = false) => {
    let checkUpdated = {};
    let {
      showUser,
      isWindowResizing,
      isWindowResizingStopped,
      isParticipantsCanvasHidden,
    } = this.state;
    if (isParticipantsCanvasHidden) {
      return sliceArr;
    }
    let { allUsers } = this.props;
    let newIndex = 0;
    sliceArr.forEach((el, index) => {
      checkUpdated[el.user_id] = {
        index: index,
        status: 2, // 2 for New
        data: el,
      };
      newIndex++;
    });
    if (isWindowResizing) {
      if (!this.state.isUpdatingCanvas) {
        this.setState((prevState) => ({
          isUpdatingCanvas: !prevState.isUpdatingCanvas,
        }));
        if (Object.values(showUser).length) {
          Object.values({ ...showUser }).forEach((el, index) => {
            if (el.data.isVideoStarted) {
              this.stopAttendeeVideo(el.data.userId);
            }
            delete showUser[el.data.user_id];
          });
        }
        this.clearVideoCanvas();
      } else if (isWindowResizing && isWindowResizingStopped) {
        this.setState({
          isWindowResizing: false,
          isWindowResizingStopped: false,
        });
        this.updateCanvas("zoomRank");
      }
    } else {
      if (Object.values(showUser).length) {
        Object.values(showUser)
          .sort(sortIndex)
          .forEach((el, index) => {
            if (checkUpdated.hasOwnProperty(el.user_id)) {
              if (el.index === checkUpdated[el.user_id].index) {
                checkUpdated[el.user_id].status = 3; // 3 for Same
              } else {
                checkUpdated[el.user_id].status = 1; // 1 for Update
              }
            } else {
              checkUpdated[el.user_id] = {
                index: newIndex,
                status: 0, // 0 for Delete
                data: el.data,
              };
              newIndex++;
            }
          });
      }
    }
    Object.values(checkUpdated)
      .sort(sortIndex)
      .sort(sortStatus)
      .forEach((el) => {
        if (CheckValue(el.data) && CheckValue(el.data.userId)) {
          if (el.status === 1 && !this.state.isUpdatingCanvas) {
            if (!el.data.stream_icon && el.data.isVideoStarted) {
              this.stopAttendeeVideo(el.data.userId);
            } else if (el.data.stream_icon) {
              if (
                !CheckValue(el.data.isVideoStarted) ||
                !el.data.isVideoStarted
              ) {
                this.startAttendeeVideo(el.data.userId, el.index);
              } else {
                this.updateAttendeeVideo(el.data.userId, el.index);
              }
            }
          } else if (el.status === 2 && !this.state.isUpdatingCanvas) {
            if (!el.data.stream_icon && el.data.isVideoStarted) {
              this.stopAttendeeVideo(el.data.userId);
            } else if (el.data.stream_icon) {
              if (
                !CheckValue(el.data.isVideoStarted) ||
                !el.data.isVideoStarted
              ) {
                this.startAttendeeVideo(el.data.userId, el.index);
              }
            }
          } else if (el.status === 0) {
            this.stopAttendeeVideo(el.data.userId);
            delete showUser[el.data.user_id];
          } else if (el.status === 3 && !this.state.isUpdatingCanvas) {
            if (!el.data.stream_icon && el.data.isVideoStarted) {
              this.stopAttendeeVideo(el.data.userId);
            } else if (el.data.stream_icon) {
              if (
                !CheckValue(el.data.isVideoStarted) ||
                !el.data.isVideoStarted
              ) {
                this.startAttendeeVideo(el.data.userId, el.index);
              }
            }
          }
          showUser[el.data.user_id] = {
            user_id: el.data.user_id,
            userId: el.data.userId,
            index: el.index,
            data: el.data,
          };
        } else {
          if (!allUsers.hasOwnProperty(el.data.user_id)) {
            this.stopAttendeeVideo(el.data.userId);
            delete showUser[el.data.user_id];
          }
        }
      });
    temp.splice(min, max);
    temp.forEach((el) => {
      if (el.isVideoStarted) {
        this.stopAttendeeVideo(el.userId);
      }
    });
    return sliceArr;
  };

  render() {
    return <Fragment></Fragment>;
  }
}

export default ZoomSDK;

const sortIndex = (a, b) => {
  var textA = a["index"];
  var textB = b["index"];
  return textA < textB ? 1 : textA > textB ? -1 : 0;
};
const sortStatus = (a, b) => {
  var textA = a["status"];
  var textB = b["status"];
  return textA < textB ? 1 : textA > textB ? -1 : 0;
};
